<template>
    <div>   
		<div class="card border-primary round h-100">
			<div class="card-body p-3">
				<h4 class="text-center"><i class="fa fa-pencil-alt"></i> &nbsp; Ulasan</h4>
				<hr />
				<ul class="nav nav-pills w-100" id="pills-tab" role="tablist">
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-100 w-lg-50 w-xl-50 px-2 py-1">
						<a class="nav-link border border-info rounded-lg shadow-sm active" data-toggle="tab" href="#menunggu-diulas-tab" role="tab" @click="get_reviewList(0)">
							<div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_review_count[0] > 0">{{ jumlah_review_count[0] }}</span></div>
							<i class="far fa-clock fa-2x mt-1"></i>
							<h6 class="mb-1">Menunggu Diulas</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-100 w-lg-50 w-xl-50 px-2 py-1">
						<a class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#riwayat-ulasan-tab" role="tab" @click="get_reviewList(1)">
							<!-- <div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_review_count[1] > 0">{{ jumlah_review_count[1] }}</span></div> -->
							<i class="fa fa-pen-square fa-2x mt-1"></i>
							<h6 class="mb-1">Riwayat Ulasan</h6>
						</a>
					</li>
				</ul>
				<div class="tab-content border rounded-lg shadow px-4 py-3 mt-2">
					<div class="tab-pane fade show active px-3 py-2" id="menunggu-diulas-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_review_count[0] <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="reviewList" v-else>
								<div class="col-md-12 mb-3" v-for="(product, index) in list_review" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-9">
											<div class="row d-flex align-items-center">
												<div class="col-md-4 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center">
														<div class="col-12 p-3 m-0">
															<img v-lazy="productImage(product.product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.product.prod_image" />
														</div>
													</div>
												</div>
												<div class="col-md-8 col-12 text-muted mb-1 w-100">
													<h6 class="text-dark mb-0">{{ product.transaction.trans_invoice }}</h6>
													<p class="mb-0">{{ product.transaction.trans_date_buy | momentFormatID }}</p>
													<hr class="m-1" />
													<h6 class="mb-0">
														{{ product.product.prod_name | capitalize }}
														<small class="text-muted">({{ product.product.prod_sku | str_limit(50) }})</small>
													</h6>
													<p class="mb-0">{{ product.product.prod_summary | str_limit(50) }}</p>
													<h6 class="text-success mb-1">{{ product.product.detail_price | formatCurrency('IDR') }}</h6>
												</div>
											</div>
										</div>
										<div class="col-lg-3">
											<button type="button" @click="prepare_review(product)" class="btn btn-outline-info w-100" data-toggle="modal" :data-target="'#modal-beri-rating-'+index"><i class="fa fa-star"></i><br />Berikan Ulasan</button>
											
											<!-- Modal review -->
											<div class="modal fade" :id="'modal-beri-rating-'+index" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
												<div class="modal-dialog modal-dialog-centered">
													<div class="modal-content">
														<div class="modal-header">
															<h5 class="modal-title" id="exampleModalLabel">Berikan Ulasan</h5>
															<button type="button" class="close" data-dismiss="modal" aria-label="Close">
															<span aria-hidden="true">&times;</span>
															</button>
														</div>
														<form @submit.prevent="add_review(index)">
															<div class="modal-body px-4">
																<div class="container">
																	<div class="row d-flex align-items-center">
																		<div class="col-md-4 text-center">
																			<img v-lazy="productImage(product.product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.product.prod_image" />
																			<h6 class="text-dark mb-0 mt-1">{{ product.product.prod_name | capitalize }}</h6>
																			<small class="text-muted">({{ product.product.prod_sku | str_limit(50) }})</small>
																		</div>
																		<div class="col-md-8">
																			<label class="text-muted mb-1">Penilaian</label>
																			<div class="text-center">
																				<div id="full-stars-example-two">
																					<div class="rating-group">
																						<input disabled checked class="rating__input rating__input--none" v-model="review_star" :id="'rating3-none'+index" value="0" type="radio">
																						<label aria-label="1 star" class="rating__label" :for="'rating3-1'+index"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
																						<input class="rating__input" v-model="review_star" :id="'rating3-1'+index" value="1" type="radio">
																						<label aria-label="2 stars" class="rating__label" :for="'rating3-2'+index"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
																						<input class="rating__input" v-model="review_star" :id="'rating3-2'+index" value="2" type="radio">
																						<label aria-label="3 stars" class="rating__label" :for="'rating3-3'+index"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
																						<input class="rating__input" v-model="review_star" :id="'rating3-3'+index" value="3" type="radio">
																						<label aria-label="4 stars" class="rating__label" :for="'rating3-4'+index"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
																						<input class="rating__input" v-model="review_star" :id="'rating3-4'+index" value="4" type="radio">
																						<label aria-label="5 stars" class="rating__label" :for="'rating3-5'+index"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
																						<input class="rating__input" v-model="review_star" :id="'rating3-5'+index" value="5" type="radio">
																					</div>
																				</div>
																			</div>
																			<label class="text-muted mb-1">Ulasan</label>
																			<div class="input-group mb-2 mr-sm-2">
																				<div class="input-group-prepend">
																					<div class="input-group-text bg-white"><i class="fa fa-pen"></i></div>
																				</div>
																				<textarea class="form-control pr-5" v-model="review_content" placeholder="Berikan ulasan di sini!" required></textarea>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="modal-footer">
																<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
																<button type="submit" class="btn btn-primary" >Kirim</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="riwayat-ulasan-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_review_count[1] <= 0">
								<h6 class="mb-0">Ulasan masih kosong</h6>
								<router-link to="/member/review" class="text-info text-decoration-none">Ayo Berikan Ulasan!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="reviewList" v-else>
								<div class="col-md-12 mb-3" v-for="(product, index) in list_review" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-12">
											<div class="row d-flex align-items-center">
												<div class="col-md-3 d-lg-block d-md-block d-none mb-1">
													<div class="row align-items-center justify-content-center">
														<div class="col-12 p-3 m-0">
															<router-link :to="'/product-detail/'+product.product.prod_id+'-'+product.product.prod_name.toLowerCase()" class="text-decoration-none">
																<img v-lazy="productImage(product.product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.product.prod_image" />
															</router-link>
														</div>
													</div>
												</div>
												<div class="col-md-9 col-12 text-muted mb-1 w-100">
													<router-link :to="'/product-detail/'+product.product.prod_id+'-'+product.product.prod_name.toLowerCase()" class="text-decoration-none">
														<h6 class="text-dark mb-0">
															{{ product.product.prod_name | capitalize }}
															<small class="text-muted">({{ product.product.prod_sku | str_limit(50) }})</small>
														</h6>
													</router-link>
													<p class="mb-0">{{ product.product.prod_summary | str_limit(50) }}</p>
													<div v-if="product.product.product_review">
														<p class="mb-0">{{ product.product.product_review.review_date | momentFormatID }}</p>
														<hr class="m-1" />
														<p class="text-muted mb-1">Penilaian Anda:</p>
														<div class="ml-3">
															<div class="d-inline-flex mx-1" v-for="index in 5" :key="index">
																<div v-if="product.product.product_review.review_star >= index">
																	<i class="fa fa-star text-warning fa-lg"></i>
																</div>
																<div v-else>
																	<i class="fa fa-star text-secondary fa-lg"></i>
																</div>
															</div>
														</div>
														<p class="text-muted mb-0 mt-2">Ulasan Anda:</p>
														<p class="text-dark mb-0 ml-3">{{ product.product.product_review.review_content | str_limit(100) }}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 w-100 text-center mt-1">
							<hr />
							<b-pagination aria-controls="reviewList" v-model="current_page" :per-page="per_page" :total-rows="jumlah_review" first-number last-number align="center" size="md"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuReview',
	data() {
		return {
			product_review: [],
			jumlah_review: 0,
			jumlah_review_count: [],
			isRefresh: false,
			review_star: 0,
			review_content: '',
			trans_invoice: '',
			prod_id: '',
			current_page: 1,
			per_page: 10,
		}
	},
	methods: {
		count_reviewList() {
			this.jumlah_review_count[0] = 0
			this.jumlah_review_count[1] = 0
			window.axios.get('/transaction?status=5', {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				let transaksi = response.data.data
				for(let i=0; i<transaksi.length; i++) {
					window.axios.get('/transaction/'+ transaksi[i].trans_id , {
							headers: {
								"token": this.$store.getters.token_member
							}
						}
					)
					.then(response2 => {
						let product = response2.data.data.detail_transaction
						for(let j=0; j<product.length; j++) {
							if(product[j].product_review == null) {
								this.jumlah_review_count[0] += 1
							}
							else {
								this.jumlah_review_count[1] += 1
							}
						}
					})
					.catch(error2 => {
						console.log(error2)
					});
				}
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		get_reviewList(id) {
			this.isRefresh = true
			this.product_review = []
			window.axios.get('/transaction?status=5', {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				let list_produk = []
				let transaksi = response.data.data
				this.jumlah_review = 0

				for(let i=0; i<transaksi.length; i++) {
					window.axios.get('/transaction/'+ transaksi[i].trans_id , {
							headers: {
								"token": this.$store.getters.token_member
							}
						}
					)
					.then(response2 => {
						let product = response2.data.data.detail_transaction
						for(let j=0; j<product.length; j++) {
							let dict = {
								"transaction": response2.data.data.transaction,
								"product": product[j]
							}
							if(id == 0 && product[j].product_review == null) {
								list_produk.push(dict)
							}
							else if(id == 1 && product[j].product_review != null) {
								list_produk.push(dict)
							}
						}
						this.jumlah_review = list_produk.length
					})
					.catch(error2 => {
						console.log(error2)
					});
				}
				this.product_review = list_produk
				this.isRefresh = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		prepare_review(product) {
			this.review_star= 0
			this.review_content= ''
			this.trans_invoice= product.transaction.trans_invoice
			this.prod_id= product.product.prod_id
		},
		add_review(index) {
			let formData = new FormData()
			formData.append('trans_invoice', this.trans_invoice)
			formData.append('prod_id', this.prod_id)
			formData.append('review_content', this.review_content)
			formData.append('review_star', this.review_star)

			window.axios.post('/transaction/review', formData, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
					}
				}
			)
			.then((response) => {
				this.count_reviewList()
				this.get_reviewList(0)

				this.$swal({
					title: 'Review Disimpan', 
					html: '<p>Terima kasih atas Ulasanya!</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
			})
			.catch((error) => {
				this.count_reviewList()
				this.get_reviewList(0)
				
				this.$swal({
					title: 'Oops!', 
					html: '<p>Terjadi Kesalahan</p>',
					icon: 'error',
					timer: 2500,
					showConfirmButton: false,
				});
			})
			$('#modal-beri-rating-'+ index).modal('hide')
		}
	},
	computed: {
		list_review : function() {
			let position = $(document).scrollTop()
			$('html, body').stop().animate({
				scrollTop: 83
			}, 1000, 'easeInOutExpo')

			return this.product_review.slice(
				(this.current_page - 1) * this.per_page, this.current_page * this.per_page,
			)
		}
	},
	created() {
		$(document).scrollTop(0)
		this.count_reviewList()
		this.get_reviewList(0)
	}
}

</script>